<template>
  <v-container fluid fill-height class="bg-dark">
    <v-layout align-center justify-center>
      <v-flex xs12 sm8 md4>
        <v-card elevation="10">
          <v-toolbar dark color="primary" elevation="0">
            <v-tabs v-model="authTabs" fixed-tabs>
              <v-tab :value="0">
                {{ appConfig.texts.login.title }}
              </v-tab>
              <v-tab :value="1"> {{ appConfig.texts.login.accountRequestTitle }}</v-tab>
            </v-tabs>
          </v-toolbar>
          <v-tabs-items v-model="authTabs">
            <!-- LOGIN -->
            <v-tab-item>
              <v-card-text>
                <v-form>
                  <v-text-field :label="appConfig.texts.login.loginLabel" v-model="user.email" filled prepend-icon="mdi-account" autocomplete="emailaddress" type="email" required></v-text-field>
                  <v-text-field :label="appConfig.texts.login.passwordLabel" v-model="user.password" filled :type="passwordType ? 'text' : 'password'" prepend-icon="mdi-lock" autocomplete="current-password" :append-icon="passwordType ? 'mdi-eye' : 'mdi-eye-off'" @click:append="passwordType = !passwordType" required></v-text-field>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-btn class="mx-auto px-15 mb-4" color="primary" @click.prevent="doLogin()">
                  {{ appConfig.texts.login.buttonLabel }}
                </v-btn>
                <v-snackbar color="red darken-1" outlined pill v-model="error" absolute style="bottom: 5px">
                  {{ errorText }}
                  <template v-slot:action="{ attrs }">
                    <v-btn color="primary" small v-bind="attrs" @click="closeError">
                      {{ appConfig.texts.login.closeErrorButtonLabel }}
                    </v-btn>
                  </template>
                </v-snackbar>
              </v-card-actions>
            </v-tab-item>
            <!-- AANVRAGEN -->
            <v-tab-item>
              <v-card-text>
                <div v-html="appConfig.texts.login.accountRequestDescription" />
              </v-card-text>
              <v-card-actions> </v-card-actions>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
const API_URL = process.env.VUE_APP_API_URL;
const INSTALL_ENV = process.env.VUE_APP_INSTALL_ENV;
export default {
  name: "Login",
  title: "",
  data() {
    return {
      installEnv: INSTALL_ENV,
      authTabs: 0,
      submitting: true,
      apiPublicUrl: API_URL,
      fromRoute: null,
      error: false,
      errorText: "",
      productsArray: null,
      passwordType: false,
      user: {
        email: "",
        password: "",
      },
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  methods: {
    closeError() {
      this.error = false;
      this.errorText = "";
    },
    doLogin() {
      this.$store.dispatch("auth/login", { user: this.user, env: this.installEnv }).then(
        () => {
          this.submitting = false;
          this.$router.push("/projects");
        },
        (reject) => {
          if (reject.data.status === 400) {
            this.error = true;
            this.submitting = false;
            this.errorText = this.appConfig.texts.login.unAuthorised;
          } else if (reject.data.status === 401) {
            this.error = true;
            this.submitting = false;
            this.errorText = this.appConfig.texts.login.needToLogin;
          } else {
            this.error = true;
            this.submitting = false;
            this.errorText = this.appConfig.texts.login.unAuthorised;
          }
        },
        (error) => {
          this.submitting = false;
          this.error = true;
          this.errorText = error.error_description;
        }
      );
    },
  },
};
</script>
